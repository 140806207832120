import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import './PaymentSuccess.css';

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box className="successBox">
      <CheckCircleOutlineIcon className="successIcon" />
      <Typography variant="h4" component="h1" className="successText" gutterBottom>
        Payment Successful
      </Typography>
      <Typography variant="body1">
        Thank you for your purchase! Your payment was successful.
      </Typography>

      <LoadingButton
        className="successBtn"
        variant="contained"
        onClick={handleGoHome}
      >
        GO TO Homepage
      </LoadingButton>
    </Box>
  );
}
