import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/CommonModal';
import AuctionBuySuccess from '../../Components/productDetails/AuctionBuySuccess';
import { setPurchaseStatus } from './reducer';
import soldIcon from '../../resources/Sold.svg';
import { loginUrl } from '../../variables';
import StyledButton from '../../Components/StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';
// import lock from '../../configs/authLock';
import { setRedirect } from '../../utils/redirect';
import './components/BuyingProductDetails.css';
import ImageSlider from './components/ImageSlider';
import Stripe from '../../Components/quickBuy/Stripe';

const BuyingProductDetails = () => {
  // lock.hide();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { productDetails, purchaseProductStatus } = useSelector(
    state => state.productDetailsReducer
  );
  const { isLoggedIn } = useSelector(state => state.loginReducer);

  const [showStripeBtn, setShowStripeBtn] = useState(!productDetails.sold);
  const [transactionId, setTrasactionId] = useState();

  useEffect(() => {
    if (purchaseProductStatus === 'success') {
      setShowSuccess(true);
    }
  }, [purchaseProductStatus]);

  const handleModalClose = () => {
    setShowSuccess(false);
    dispatch(setPurchaseStatus(false));
  };
  const endDate = new Date(productDetails.displayExpireDate);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          {productDetails.sold && (
            <div className="product-details-icon-over-img">
              <img
                src={soldIcon}
                alt="closed"
                className="product-details-sold-icon"
              />
            </div>
          )}
          <ImageSlider images={productDetails.images} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="product-details-price">
                Price:{' '}
                <span className="product-owner-name">
                  ${productDetails.price}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="product-details-description-header">
                Description
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="product-details-description">
                {productDetails.details}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="Expire-date">
                {
                  <>
                    <strong>Available on:</strong> {endDate.toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    })}
                  </>
                }
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="product-owner">
                Shop/ owner:{' '}
                <span className="product-owner-name">
                  {productDetails.storeName}
                </span>
              </div>
            </Grid>

            {showStripeBtn && isLoggedIn && (
              <Grid
                item
                xs={12}
                my={3}
                display={{ xs: 'flex', md: 'block' }}
                justifyContent="center"
              >
                <div className="paypal-btn">
                  <Stripe
                    shape="pill"
                    setShowPaypal={setShowStripeBtn}
                    product={productDetails}
                    setTrasactionId={setTrasactionId}
                  />
                </div>
              </Grid>
            )}
            {!productDetails.sold && !isLoggedIn && (
              <Grid
                item
                xs={12}
                my={3}
                display={{ xs: 'flex', md: 'block' }}
                justifyContent="center"
              >
                <div className="product-details-buy-btn">
                  <StyledButton
                    fullWidth
                    onClick={() => {
                      setRedirect(pathname);
                      navigate(loginUrl);
                    }}
                  >
                    Buy
                  </StyledButton>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CommonModal
        transactionId={transactionId}
        open={showSuccess}
        handleClick={handleModalClose}
      >
        <AuctionBuySuccess
          type="buy"
          handleClose={() => {
            setShowSuccess(false);
            dispatch(setPurchaseStatus('idle'));
          }}
        />
      </CommonModal>
    </>
  );
};

export default BuyingProductDetails;
