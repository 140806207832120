import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Grid, Typography, Container, Box, useMediaQuery, useTheme } from '@mui/material';
import { getPurchaseHistory } from '../action';
import './HistoryComponents.css'; // Ensure this is imported
import ImageSlider from '../../ProductDetails/components/ImageSlider';
import CircularProgressLoader from '../../../Components/CircularProgressLoader';
import EmptyMessage from '../../../Components/emptyMessage/EmptyMessage';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import StyledButton from '../../../Components/StyledButton';
import { useNavigate } from 'react-router-dom';
import { setSelectedItemsFilter } from '../../ProductLists/reducer';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const getDate = (fullDate) => {
  const inputDate = new Date(fullDate);
  const month = inputDate.getMonth();
  const date = inputDate.getDate();
  const year = inputDate.getFullYear();
  return `${monthNames[month]} ${date}, ${year}`;
};

const HistoryComponent = ({ type }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const history = useSelector((state) => state.userHistoryReducer);
  const [sortedHistory, setSortedHistory] = useState([]);
  const navigate = useNavigate();
  const { purchases, purchaseStatus } = useSelector(
    state => state.userHistoryReducer
  );
  const { selectedStores } = useSelector(state => state.storeReducer);

  useEffect(() => {
    if (!history.purchases.length) {
      dispatch(getPurchaseHistory());
    }
  }, [dispatch, history.purchases.length]);

  useEffect(() => {
    const sortedHistory = [...history.purchases].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setSortedHistory(sortedHistory);
  }, [history.purchases]);


  const handleClick = () => {
    if (selectedStores.length > 0) {
      dispatch(setSelectedItemsFilter(['available', 'buy']));
      navigate('/buy');
    } else {
      navigate('/home', { state: { navigateTo: '/buy' } });
    }
  };

  return (
    <>
      <div style={{ backgroundColor: '#FF5943', padding: '20px 0 0 0' }}>
        <Container maxWidth="lg" sx={{ py: 3 }}>
          <Typography
            variant="h3"
            color="white"
            letterSpacing="-0.333333px"
            sx={{
              fontWeight: { xs: 700, md: 800 },
              fontSize: { xs: '20px', md: '36px' },
              lineHeight: { xs: '23.44px', md: '42px' }
            }}
          >
            Purchase History
          </Typography>
        </Container>
      </div>
      <div className={mdScreen ? 'bid-history-mb' : 'bid-history-lg'}>
        <Container maxWidth="lg" sx={{ py: 1.6 }}>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '10px'
            }}
          >
            {purchaseStatus === 'pending' ? (
              <CircularProgressLoader />
            ) : purchaseStatus === 'success' && !purchases.length ? (
              <EmptyMessage
                emptyIcon={
                  <ProductionQuantityLimitsIcon sx={{ fontSize: 50 }} />
                }
              >
                <Grid item xs={12}>
                  You have no Purchase records!
                </Grid>
                <Grid item xs={12}>
                  Please click below to browse and buy any products now!
                </Grid>
                <Grid item>
                  <StyledButton
                    color={{ xs: '#FF5943', md: '#193C46' }}
                    onClick={handleClick}
                  >
                    Buy Products
                  </StyledButton>
                </Grid>
              </EmptyMessage>
            ) : (
              <Grid container p={2} flexDirection="column">
                {sortedHistory.map((item, index) => (
                  <Grid container spacing={2} key={index} alignItems="flex-start">
                    <Grid item xs={12} md={3}>
                      <ImageSlider images={item.images || []} />
                    </Grid>
                    <Grid
                      className="test-grid"
                      item xs={12} md={6}
                      flexGrow={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography
                        variant="body1"
                        m={0}
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="30px"
                        letterSpacing="-0.33px"
                        className="bid-history-date"
                      >
                        Date: {getDate(item.createdAt) || null}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize="30px"
                        fontWeight="600"
                        lineHeight="35px"
                        letterSpacing="-0.33px"
                        py={{ xs: 1, md: 2 }}
                        className="bid-history-name"
                      >
                        {item.productName}
                      </Typography>
                      <Box m={0}>
                        <Typography
                          variant="body1"
                          fontWeight="600"
                          fontSize="20px"
                          lineHeight="30px"
                          letterSpacing="-0.33px"
                          className="bid-history-store"
                        >
                          Shop:{' '}
                          <span className="history-font-color">
                            {item.storeName}
                          </span>
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography
                              fontWeight="600"
                              fontSize="20px"
                              lineHeight="30px"
                              letterSpacing="-0.33px"
                              className="bid-history-store"
                            >
                              Price:{' '}
                              <span className="history-font-color">
                                ${item.price}
                              </span>
                            </Typography>
                            <Typography
                              fontWeight="600"
                              fontSize="20px"
                              lineHeight="30px"
                              letterSpacing="-0.33px"
                              className="bid-history-store"
                            >
                              Transaction ID:{' '}
                              <span className="history-font-color transaction-id-container">
                                <span className="transaction-id">
                                  {`${item.externalTxnId.slice(0, 10)} `}
                                  <span className="transaction-id-dots">...</span>
                                </span>
                                <span className="transaction-id-full">
                                  {item.externalTxnId}
                                </span>
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 2 }} />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default HistoryComponent;
