import React from 'react';
import { CardMedia } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css'

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      <span>&#10095;</span>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      <span>&#10094;</span>
    </div>
  );
};

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {(images || []).map((image, index) => (
        <div key={index} >
          <CardMedia
            className="custom-img"
            component="img"
            image={image}
            alt={`Product Image ${index + 1}`}
          />
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
